import * as React from "react"
import Layout from "../components/layout.js"
import Image from "../components/image"

const NightGodTypeIPage = () => {
  return (
    <Layout title="Monarch Butterfly Spirit">
        <h2>Field Notes</h2>
        <center><Image src="monarchbutterfly-1.png" alt="Drawing" /></center>
    </Layout>
  )
}

export default NightGodTypeIPage